import React, { FC } from 'react';

const AlbumSortInstructions: FC = () => {
    return (
        <div style={{display: "block", width: '100%'}}>
            <h1>Album Sort</h1>
            <p>Do you like music? Do you like creating lists? I do. I love music and I love making huge topsters and lists about my favorite music. But there's one problem. Sometimes, the lists I want to make are so huge that it feels impossible to sort through it all. This is where the idea of Album Sort came in. If you've ever watched the Social Network, this is kinda like <em>Facemash</em> except with albums and not pervy and morally wrong. Basically, get a list of albums, put them in head-to-head one-on-one matchups with each other, pick the album you like more, and adjust the rankings accordingly. Like chess and like Mark Zuckerberg's Facemash, I use an ELO rating system to determine how an album's rating should be adjusted.</p>
            <h2>Instructions</h2>
            <p>Use manual mode to search and add albums manually or get a list of albums from your Last.fm username, pick which album you like better, and see how their rankings change at the bottom</p>
        </div>
    );
};

export default AlbumSortInstructions;