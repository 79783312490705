import React, { FC } from 'react'
import HomeCSS from '../css/Home.module.css';

const Home:FC = () => {

  return (
      <div className={HomeCSS.homepage}>
        <h1>Welcome!</h1>
        <p>This website acts as a hub for contact info, but more importantly my personal playground and a way for me to show off the projects and cool stuff I'm doing.</p>
        <p>Check out my blog where I ramble about mostly non-Computer Science stuff like movies, music, and culture.</p>
        <p>"Album Sort" is a cool tool I made to sort and rank albums by ELO rating.</p>
        <p>"Spotify Sequencer" is my favorite project where users can sign in to their Spotify Account and sequence playlists they have using an algorithm I've developed. Right now it's still in developer mode, but I'm waiting on approval from Spotify to open it up to the public!</p>
      </div>
  )
}

export default Home;