import { playlist } from '../../../types';
import spotifyLogo from '../../../Images/Spotify_Icon_RGB_Black.png';

interface PlaylistSearchResultProps {
  playlist: playlist,
  choosePlaylist: (playlist: playlist) => void
}

const PlaylistSearchResult = ({ playlist, choosePlaylist }: PlaylistSearchResultProps) => {
  // Handle selecting a playlist
  function handleSelect() {
    choosePlaylist(playlist);
  }

  return (
    <div className=""
      style={{ cursor: "pointer" }}
      onClick={handleSelect}
    >
      <table className="table-fixed w-full">
        <tbody>
          <tr>
            <td className="w-16">
              <img src={playlist.cover_url} style={{height: '64px',width: '64px'}} alt={playlist.name} />
            </td>
            <td className="w-10/12 truncate" style={{display: "block"}}>
              <div className="ml-1">
                <div>{playlist.name}</div>
                <div className="text-gray-400">by {playlist.owner.display_name}</div>
                <div className="text-gray-400 truncate">{playlist.description === '' ? '-' : playlist.description}</div>
              </div>
            </td>
            <td className="w-1/12">
              <a href={playlist.external_url} target="_blank" rel="noreferrer">
                <img src={spotifyLogo} style={{ height: '24px',width: '24px', marginRight: '10px'}} alt="spotify logo" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>  
    </div>
  );
}

export default PlaylistSearchResult;