import { FC } from 'react'
import ReactMarkdown from 'react-markdown';
import BlogPostPageCSS from '../css/BlogPostPage.module.css';

interface BlogPostPageProps {
    markdownContent: string;
}

const BlogPostPage:FC<BlogPostPageProps> = ({ markdownContent }) => {
    return (
        <div className={BlogPostPageCSS.blog_post} style={{padding: "1rem 1rem 0"}}>
            <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </div>
    );
};

export default BlogPostPage;