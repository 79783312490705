import { FC, useState } from 'react'
import AlbumSort from '../Components/AlbumSort/AlbumSort';
import LastFMSort from '../Components/AlbumSort/LastFMSort';
import AlbumSortInstructions from '../Components/AlbumSort/AlbumSortInstructions';

const AlbumSortPage: FC = () => {
  const [manualMode, setManualMode] = useState(true);

  const handleManualModeToggled = () => {
    setManualMode(true);
  }
  
  const handleLastFMModeToggled = () => {
    setManualMode(false);
  }

  return (
        <div>
          <AlbumSortInstructions />
          <div className="flex justify-center items-center">
            <button className={manualMode ? 'bg-blue-500 hover:bg-blue-700 py-2 px-4 rounded' : 'bg-gray-500 hover:bg-gray-700 py-2 px-4 rounded'} onClick={handleManualModeToggled}>
              Manual
            </button>
            <button className={manualMode ? 'bg-gray-500 hover:bg-gray-700 py-2 px-4 rounded' : 'bg-blue-500 hover:bg-blue-700 py-2 px-4 rounded'} onClick={handleLastFMModeToggled}>
              LastFM
            </button>
          </div>
          {manualMode ? <AlbumSort/> : <LastFMSort/>}
        </div>
    );
}

export default AlbumSortPage