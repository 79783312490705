import {
  track,
  stats_artist,
  artist,
  playlist
} from '../types';
import missing_texture from '../Images/missing_texture.png';

// ALBUM IMAGE FUNCTIONS
//
//
// ALBUM IMAGE FUNCTIONS

// Get the SMALLEST album image in url form from the type 'SpotifyApi.ImageObject[]'
function smallestAlbumImageUrl(response: SpotifyApi.ImageObject[]): string {
  if (response.length === 0) return missing_texture;
  return response.reduce(
    (smallest, image) => {
      if ((image.height as number) < (smallest.height as number)) return image
      return smallest
    },
    response[0]
  ).url;
}

// Get the LARGEST album image in url form from the type 'SpotifyApi.ImageObject[]'
export function largestAlbumImageUrl(response: SpotifyApi.ImageObject[]): string {
  if (response.length === 0) return missing_texture;
  return response.reduce(
    (largest, image) => {
      if ((image.height as number) > (largest.height as number)) return image
      return largest
    },
    response[0]
  ).url;
}

// ARTIST FUNCTIONS
//
//
// ARTIST FUNCTIONS

// ArtistObjectSimplified[] ==> artist[]
// Needed for playlist.ts
export function ArtistObjectSimplifiedArrayToArtistArray(response: SpotifyApi.ArtistObjectSimplified[]): artist[] {
  return response.map((artist) => {
    return {
      id: artist.id,
      name: artist.name,
      uri: artist.uri,
      href: artist.href,
    }
  });
}

// ArtistObjectFull[] ==> stats_artist[]
// Needed for Stats.tsx
export function ArtistObjectFullToArtists(response: SpotifyApi.ArtistObjectFull[]): stats_artist[] {
  return response.map(artist => {
    return {
      id: artist.id,
      name: artist.name,
      uri: artist.uri,
      href: artist.href,
      image_url: largestAlbumImageUrl(artist.images)
    }
  });
}

// TRACK FUNCTIONS
//
//
// TRACK FUNCTIONS

// TrackObjectFull[] ==> track[]
// Needed for Stats.tsx and Wavify.tsx
export function TrackObjectFullToTracks(response: SpotifyApi.TrackObjectFull[]): track[] {
  return response.map((track) => {
    return {
      id: track.id,
      title: track.name,
      artists: ArtistObjectSimplifiedArrayToArtistArray(track.artists),
      track_num: track.track_number,
      uri: track.uri,
      href: track.href,
      cover_url: smallestAlbumImageUrl(track.album.images),
      external_url: track.external_urls.spotify
    }
  });
}

// PlaylistTrackObject[] ==> track[]
// Needed for playlist.ts
export function PlaylistTrackObjectArrayToTrackArray(playlistTrackObjectArray: SpotifyApi.PlaylistTrackObject[]): track[] {
  const map_tracks: (SpotifyApi.TrackObjectFull | null)[] = playlistTrackObjectArray.map(track => track.track);
  const tracks: (SpotifyApi.TrackObjectFull)[] = map_tracks.filter((item): item is SpotifyApi.TrackObjectFull => item !== null); // Filter out any and all null values
  return tracks.map((track) => {
    // if (track.is_local) {
    //   console.error('Error Track.. Removing ', track.name);
    //   return seq_empty_track;
    // }
    return {
      id: track.id,
      title: track.name,
      artists: ArtistObjectSimplifiedArrayToArtistArray(track.artists),
      track_num: track.track_number,
      uri: track.uri,
      href: track.href,
      is_local: track.is_local,
      cover_url: largestAlbumImageUrl(track.album.images),
      external_url: track.external_urls.spotify
    }
  })
}

// PLAYLIST FUNCTIONS
//
//
// PLAYLIST FUNCTIONS

// PlaylistObjectSimplified ==> playlist
// Needed for Sequencer.tsx
export function PlaylistObjectSimplifiedToPlaylist(response: SpotifyApi.PlaylistObjectSimplified): playlist {
  return {
    id: response.id,
    name: response.name,
    description: (response.description) ? response.description : "",
    uri: response.uri,
    href: response.href,
    cover_url: largestAlbumImageUrl(response.images),
    tracks: [],
    length: response.tracks.total,
    owner: response.owner,
    external_url: response.external_urls.spotify
  }
}