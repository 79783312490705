import { FC } from 'react'
import { BrowserRouter, Routes, Route, Outlet} from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Home from '../Pages/Home';
import About from '../Pages/About';
import AlbumSortPage from '../Pages/AlbumSortPage';
import Blog from '../Pages/Blog';
import Spotify from '../Pages/Spotify';

const Router:FC = () => {

  const Layout:FC = () => {
    return (
      <div className="container mx-auto" style={{padding: 0}}>
        <Header />
        <Outlet />
        <Footer />
      </div>
    );
  };

  const BrowserRoutes = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />}/>
            <Route path="/albumsort" element={<AlbumSortPage />}/>
            <Route path="/blog/*" element={<Blog />} />
            <Route path="/spotify" element={<Spotify />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  };

  return (
    <BrowserRoutes />
  );
};

export default Router;
