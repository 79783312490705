// /* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { Album } from '../../types';
import { updateRating} from '../../Helpers'
import AlbumContestCSS from '../../css/AlbumContest.module.css';

interface AlbumContestProps {
    albumList: Album[],
    updateAlbumList: (
        myAlbumList: Album[]
    ) => void;
}

const AlbumContest: FC<AlbumContestProps> = ({ albumList, updateAlbumList }) => {
    const [leftAlbum, setLeftAlbum] = useState<Album>(albumList[0]);
    const [rightAlbum, setRightAlbum] = useState<Album>(albumList[1]);
    useEffect(() => {
        generateMatchup()
    }, [albumList])
    
    const generateMatchup = () => {
        let lastMatchups: Album[] = [leftAlbum, rightAlbum];
        if (albumList.length-2 <= 2) {
            lastMatchups = [];
        }

        let filterList: Album[] = albumList.filter((album) => !lastMatchups.includes(album));
        const rand1: Album = filterList[Math.floor(Math.random() * filterList.length)];
        lastMatchups.push(rand1);
        filterList = albumList.filter((album) => !lastMatchups.includes(album));
        const rand2: Album = filterList[Math.floor(Math.random() * filterList.length)];
        lastMatchups.shift();
        lastMatchups.shift();
        setLeftAlbum(rand1);
        setRightAlbum(rand2);
    }


    const handlePickedAlbum = (winner: Album, loser: Album) => {
        // console.log(`picked ${winner.title} over ${loser.title}`)
        winner.ELO = updateRating(winner.ELO, loser.ELO, 1);
        loser.ELO = updateRating(loser.ELO, winner.ELO, 0);
        let newAlbumList = albumList.map((album) => {
            if (album === winner) {
                return winner;
            } else if (album === loser) {
                return loser;
            } return album;
        });
        updateAlbumList(newAlbumList);
    }

    const displaySingleAlbum = (player: Album, opponent: Album) => {
        return (
            <div className={AlbumContestCSS.singleAlbum}>
                <img className={AlbumContestCSS.albumImage}
                    src={player.coverURL}
                    alt="albumcover"
                    onClick={() => handlePickedAlbum(player, opponent)}
                />
                <p className={AlbumContestCSS.text}>{player.artist}</p>
                <p className={AlbumContestCSS.text}><em>{player.title}</em></p>
            </div>
        )
    }

    return (
        <div className={AlbumContestCSS.contest}>
            {displaySingleAlbum(leftAlbum, rightAlbum)}
            {displaySingleAlbum(rightAlbum, leftAlbum)}
        </div>
    );
};

export default AlbumContest