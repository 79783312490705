import { Album } from "../types";
import { ELO_K_FACTOR } from "./constants";

// Function to calculate the probability ratingA beating ratingB
export function Probability(ratingA: number, ratingB: number) {
    const prob = (1 / (1 + Math.pow(10, (ratingB-ratingA) / 400)));
    return prob;
};

// Calculate the expected score (number between 0-1) of 'player'
export function ExpectedScore(playerRating: number, opponentRating: number) {
    const prob = (1 / (1 + Math.pow(10, (opponentRating-playerRating) / 400)));
    return prob;
};


export function ELORating(winner: Album, loser: Album, K: number) {
    const Pa = Probability(winner.ELO, loser.ELO) // Probability of the winner beating the loser
    const Pb = Probability(loser.ELO, winner.ELO) // Probability of the loser upsetting the winner

    // maybe... albumList.albums ..(find the winner) .ELO = ____
    winner.ELO = winner.ELO + K * (1-Pa) // ADJUSTING THE WINNER
    loser.ELO = loser.ELO + K * (0-Pb) // ADJUSTING THE LOSER

    return winner.ELO + K * (1-Pa)
}

// Score is 1 or 0. 1 if player wins and opponent loses. 0 if opponent wins and player loses
export function updateRating(playerRating: number, opponentRating: number, playerScore: number) {
    const expectedScore = ExpectedScore(playerRating, opponentRating);
    const deltaRating = ELO_K_FACTOR * (playerScore - expectedScore);
    return playerRating + deltaRating;
}