import { useState, useEffect } from 'react';
import axios from 'axios';

interface useAuthProps {
  code: string;
  endpoint: string;
}

// Set the restAPI url and redirect URI according to if im in a dev environment or not.
let restapiurl = 'https://us-central1-pauly-fb.cloudfunctions.net/app';
let redirecturi = 'https://jackpauly.com'

if (process.env.NODE_ENV === 'development') {
  restapiurl = 'http://127.0.0.1:5001/pauly-fb/us-central1/app';
  redirecturi = 'http://localhost:3000'
}

// returns the accessToken
export function useAuth({ code, endpoint }: useAuthProps) {
  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [expiresIn, setExpiresIn] = useState();
  useEffect(() => {
    axios
      .post(restapiurl+'/login', {
        grant_type:'authorization_code',
        code: code,
        redirect_uri: `${redirecturi}${endpoint}`
      })
      .then(res => {
        setAccessToken(res.data.accessToken);
        setRefreshToken(res.data.refreshToken);
        setExpiresIn(res.data.expiresIn);
        window.history.pushState({}, '', endpoint);
      })
      .catch((err) => {
        console.log('login error');
        console.log(err);
        const win: Window = window;
        win.location= endpoint;
      })
  }, [code, endpoint]);

  useEffect(() => {
    if (!refreshToken || !expiresIn) return;
    const interval = setInterval(() => {
      axios
      .post(restapiurl+'/refresh', {
        grant_type:'refresh_token',
        refresh_token: refreshToken,
        redirect_uri: `${redirecturi}${endpoint}`
      })
      .then(res => {
        setAccessToken(res.data.access_token);
        setExpiresIn(res.data.expires_in);
      })
      .catch(() => {
        const win: Window = window;
        win.location= endpoint;
      })
    }, (expiresIn - 60) * 1000);

    return () => clearInterval(interval);
  }, [refreshToken, expiresIn, endpoint]);

  return accessToken;
}