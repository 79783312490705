import { FC, useEffect, useState } from 'react'
import { Album } from '../../types';
import { hasImage, searchAlbums, ELO_INITAL_RATING } from '../../Helpers';
import AlbumContest from './AlbumContest';
import AlbumSearchResult from './AlbumSearchResult';
import { DisplayAlbumList } from './DisplayAlbum';
import AlbumSortCSS from '../../css/AlbumSort.module.css';

const AlbumSort: FC = () => {
    const [albumList, setAlbumList] = useState<Album[]>(() => {
        const storedAlbumList = localStorage.getItem('albumlist');
        return storedAlbumList ? JSON.parse(storedAlbumList) : [];
    });
    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState<Album[]>([]);

    // useEffect for changes to albumList
    useEffect(() => {
        localStorage.setItem('albumlist', JSON.stringify(albumList));
    }, [albumList]);

    // UseEffect for searching for albums
    useEffect(() => {
        let cancel: any = false;
        searchAlbums(search).then(res => {
            if (cancel) return;
            setSearchResults(
                res.filter((obj) => hasImage(obj) === true)
                .filter((obj) =>  !albumList.map((album) => {
                    return album.id;
                }).includes(obj.artist+"_"+obj.name))
                .map(album => {
                    return {
                        id: (album.artist+"_"+album.name),
                        title: album.name,
                        // title: (album.name.length > 30 ? (album.name.substring(0,27)+"...") : album.name),
                        artist: album.artist,
                        ELO: ELO_INITAL_RATING,
                        coverURL: album.image[album.image.length-1]['#text']
                    }
                })
            );
        })

        return () => {
            cancel = true;
        };
    }, [search, albumList])

    // Function to add an album
    function addAlbum(album: Album) {
        console.log("adding..", album);
        if ( albumList.some((obj) => obj.title === album.title && obj.artist === album.artist) ) {
            console.log(`album already added`);
            return;
        }
        setAlbumList((prevAlbumList) => (
            [...prevAlbumList, album]
        ));
        // setSearch('');
    }

    // function to remove an album
    function removeAlbum(album: Album) {
        const updatedList = albumList.filter((obj) => obj !== album)
        setAlbumList(updatedList);
    }

    const handleUpdateAlbumList = (newAlbumList: Album[] ) => {
        setAlbumList(newAlbumList);
    };

    const resetELOs = () => {
        const resetAlbumList: Album[] = albumList
        resetAlbumList.map((album) => (
            album.ELO = ELO_INITAL_RATING
        ));
        setAlbumList(resetAlbumList);
        console.log("ELOS RESET")
    };

    const clearTable = () => {
        setAlbumList([]);
    }

    return (
        <div className={AlbumSortCSS.tableContainer}>
            {(albumList.length < 2) ? <></>
            : <AlbumContest albumList={albumList} updateAlbumList={handleUpdateAlbumList}/>}
            <div className="flex flex-col py-2" style={{
                height: '50vh'}}>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="search"
                    type="search"
                    placeholder="Search Album"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                <div className="grow my-2" style={{ overflowY: "auto"}}>
                    {searchResults.map((album, key) => (
                        <AlbumSearchResult album={album} key={key} chooseAlbum={addAlbum}/>
                    ))}
                </div>
            </div>
            <h1>RESULTS</h1>
            {DisplayAlbumList({albumList, removeAlbum})}
            <div className="flex flex-row justify-end">
                <div className={AlbumSortCSS.reset}><button className="bg-red-500 hover:bg-red-700 py-2 px-4 rounded" onClick={resetELOs}>Reset Rankings</button></div>
                <div className={AlbumSortCSS.reset}><button className="bg-red-500 hover:bg-red-700 py-2 px-4 rounded" onClick={clearTable}>Clear Table</button></div>
            </div>
        </div>
    );
};

export default AlbumSort;