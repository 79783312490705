import React, { FC } from 'react';
import Router from './Components/Router';

const App: FC = () => {
  return (
    <section className="App">
      <Router />
    </section>
  );
};

export default App;