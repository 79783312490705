// The base types for spotify *things*

// Base type for a spotify track
export type track = {
  id: string;
  title: string;
  artists: artist[];
  track_num: number;
  uri: string;
  href: string;
  is_local?: boolean;
  cover_url: string;
  external_url: string;
}

// Base type for a spotify artist
export type artist = {
  id: string,
  name: string,
  uri: string,
  href: string
}

// Base type for a spotify album
export type album = {
  id: string,
  title: string,
  artists: artist[],
  album_type: 'album' | 'single' | 'compilation',
  release_date: string,
  uri: string,
  href: string,
  cover_url: string,
}

// Base type for a spotify playlist
export type playlist = {
  id: string;
  name: string;
  description: string;
  uri: string;
  href: string;
  cover_url: string;
  length: number;
  owner: user;
  tracks: track[];
  external_url: string;
}

// Base type for a spotify user
export type user = {
  id: string;
  display_name?: string;
  uri: string;
  href: string;
  image_url?: string;
  external_url?: string;
}

export const empty_track: track = {
  id: "",
  title: "",
  artists: [],
  track_num: -1,
  uri: "",
  href: "",
  cover_url: "",
  external_url: "",
}

export const empty_user: user = {
  id: "",
  display_name: "",
  uri: "",
  href: "",
  image_url: "",
  external_url: ""
}

export const empty_playlist: playlist = {
  id: "",
  name: "",
  description: "",
  uri: "",
  href: "",
  cover_url: "",
  length: 0,
  owner: empty_user,
  tracks: [],
  external_url: ""
}

export interface sp_auth {
  access_token: string | undefined,
  refresh_token: string | undefined,
  expires_in: number | undefined
}