import SpotifyWebApi from 'spotify-web-api-node';
import {
  artist,
  empty_audio_features,
  playlist,
  seq_empty_playlist,
  seq_playlist,
  seq_track,
  track
} from '../types';
import {
  imageUrlToBase64,
  PlaylistTrackObjectArrayToTrackArray,
  sequence
} from '../Helpers';

export class Playlist {
  spotifyApi: SpotifyWebApi; // The Spotify Web API handler
  playlist: playlist; // The base playlist to display upon selecting a playlist
  sequenced: seq_playlist; // The playlist with all the tons of data
  newPlaylistID: string; // the playlist id
  static GenreMap: Map<string, string[]> = new Map<string, string[]>();
  static RelatedArtistsMap: Map<string, artist[]> = new Map<string, artist[]>();
  static AudioFeaturesMap: Map<string, SpotifyApi.AudioFeaturesObject> = new Map<string, SpotifyApi.AudioFeaturesObject>();
  constructor(spotifyApi: SpotifyWebApi, playlist: playlist) {
    this.spotifyApi = spotifyApi;
    this.playlist = playlist;
    this.sequenced = seq_empty_playlist;
    this.newPlaylistID = "";
  }

  // Fetches the basic playlist and its tracks. Only a few API calls
  public async fetchPlaylist(): Promise<void> {
    const tracks = await this.fetchTracks();
    const playlist_update: playlist = {
      ...this.playlist,
      tracks: tracks
    }
    this.playlist = playlist_update;
  }

  // Method that gets the nitty gritty data of the playlist. Many API calls.
  public async updatePlaylist(): Promise<void> {
    if (this.sequenced.dataget) {
      return;
    }
    const tracks_update: seq_track[] = await this.UpdateTracks(this.playlist.tracks);
    if (tracks_update.length === 0) {
      console.log('empty')
      return;
    }
    const sequenced_update: seq_playlist = {
      ...this.playlist,
      tracks: tracks_update,
      dataget: true
    }
    this.sequenced = sequenced_update;
  }
  
  private async fetchTracks(): Promise<track[]> {
    const tracks: track[] = [];
    for (let i=0; i<this.playlist.length; i+=100) {
      const trackPromise = this.spotifyApi.getPlaylistTracks(this.playlist.id, {limit: 100, offset: i});
      const items = (await trackPromise).body.items;
      PlaylistTrackObjectArrayToTrackArray(items).map((track) => (
        tracks.push(track)
      ))
    }
    return tracks;
  }

  // Update the track array to add..
  // 1. Audio Features
  // 2. Artist Data
  async UpdateTracks(tracks: track[]): Promise<seq_track[]> {
    // Get the artist IDS here...
    // artist_ids is an array of strings.
    // 1. Get the ids from each track
    // 2. Flatten the array
    // 3. Filter out any duplicates
    // 4. Set a ReadonlyArray of the artist ids
    const artist_ids: string[] = tracks.map(track => {
      let ids = track.artists.map(artist => artist.id);
      return ids;
    }).flat().filter((elem, index, self) => {
      return index === self.indexOf(elem);
    }).filter((item): item is string => item !== null)
    const read_only_artist_ids: ReadonlyArray<string> = artist_ids;
    // Set a ReadonlyArray of the track ids
    const readonly_track_ids: ReadonlyArray<string> = tracks.map(track => track.id).filter((item): item is string => item !== null);

    // Get the genre(s) of each artist and put it into a static (Hash)Map
    // Get the related artists of each artist and put it into another static (Hash)Map
    if (read_only_artist_ids.length > 0) {
      // Loop, request 50 at a time...
      for (let i=0; i<read_only_artist_ids.length; i+=50) {
        (await this.spotifyApi.getArtists(read_only_artist_ids.slice(i, i+50))).body.artists.map(artist => (
          Playlist.GenreMap.set(artist.id, artist.genres)
        ));
      }
      for (let i=0; i<read_only_artist_ids.length; ++i) {
        const relatedArtists: artist[] = (await this.spotifyApi.getArtistRelatedArtists(read_only_artist_ids[i])).body.artists.map(artist => {
          return {
            id: artist.id,
            name: artist.name,
            uri: artist.uri,
            href: artist.href
          }
        });
        Playlist.RelatedArtistsMap.set(read_only_artist_ids[i],relatedArtists);
      }
    }


    // Get the audio features here.
    // Get the audio features of each track and put it into a static (Hash)Map
    if (readonly_track_ids.length > 0) {
      for (let i=0; i<readonly_track_ids.length; i+=50) {
        (await this.spotifyApi.getAudioFeaturesForTracks(readonly_track_ids.slice(i, i+50))).body.audio_features.map(features => (
          Playlist.AudioFeaturesMap.set(features.id, features)
        ))
      }
    }

    const tempos = Array.from(Playlist.AudioFeaturesMap.values()).map(features => features.tempo);
    let [mintempo, maxtempo] = [Math.min(...tempos), Math.max(...tempos)];
    const norm = (tempo: number) => {
      return ((tempo - mintempo) / (maxtempo - mintempo));
    }

    // Filter out local files
    const spotify_tracks: track[] = tracks.filter(track => track.is_local === false);

    // Return the playlist
    return spotify_tracks.map(track => {
      const [audioFeatures, genres, related_artists] = getTrackAttributes(track);
      // Normalize the tempo
      const audio_features: SpotifyApi.AudioFeaturesObject = {
        ...audioFeatures,
        tempo: norm(audioFeatures.tempo)
      }

      return {
        ...track,
        artists: track.artists,
        audio_features: audio_features,
        audio_score: audioScore(audio_features),
        genres: genres,
        related_artists: related_artists
      }
    })
  }

  // Sequence the playlist
  public async sequencePlaylist() {
    // If the sequenced playlist has not made the required API calls yet to make it a full seq_playlist, do so
    // For some reason, when the massive API call bonanza triggers, only THEN will the spinner render
    if (!this.sequenced.dataget) {
      (await this.updatePlaylist())
      // console.log('getting info...')
    } else {
      // NEED TO DO THIS WEIRDNESS TO WORK AGHHHH
      // JUST NEED TO MAKE A POINTLESS API CALL???? FOR SOME REASON?? IM GONNA CRY
      (await this.spotifyApi.getMe())
    }
    this.sequenced = (sequence(this.sequenced, this.playlist));
    // (await this.updatePlaylist())
  }

  // Save playlist?
  public async savePlaylist() {
    // Create a new playlist if need be
    if ( this.newPlaylistID === "") {
      this.newPlaylistID = (await this.spotifyApi.createPlaylist(this.sequenced.name, {description: this.sequenced.description})).body.id;
    }

    // Get Track URIs
    const readonly_track_uris: ReadonlyArray<string> = this.sequenced.tracks.map(track => track.uri);
    const readonly_track: ReadonlyArray<{uri: string, positions?: ReadonlyArray<number> | undefined}> = this.sequenced.tracks.map(track => {
      return {uri: track.uri}
    });

    // Removing all tracks from sequenced playlist. Clearing playlist
    for (let i=0; i<readonly_track.length; i+=100) {
      await this.spotifyApi.removeTracksFromPlaylist(this.newPlaylistID, readonly_track.slice(i, i+100));
    }

    // Adding tracks to playlist
    for (let i=0; i<readonly_track_uris.length; i+=100) {
      await this.spotifyApi.addTracksToPlaylist(this.newPlaylistID, readonly_track_uris.slice(i, i+100), {position: i});
    }
    
    // Setting the image
    imageUrlToBase64(this.playlist.cover_url).then(async res => {
      const data = res.substring(res.indexOf(',') + 1)
      await this.spotifyApi.uploadCustomPlaylistCoverImage(this.newPlaylistID, data)
    })
  }

  // Getters and Setters
  public getPlaylist(): playlist {
    return this.playlist;
  }
  public getSequenced(): seq_playlist {
    return this.sequenced;
  }

  public setPlaylist(playlist: seq_playlist): void {
    this.playlist = playlist;
  }

  public setSequenced(sequenced: seq_playlist): void {
    this.sequenced = sequenced;
  }
}

// weighted audio score
const audioScore = (audio_features: SpotifyApi.AudioFeaturesObject): number => {
  const attributes: number[] = [
    audio_features.energy,
    audio_features.valence,
    audio_features.danceability
  ];
  const weights: number[] = [7, 1.5, 1.5]; // for energy, tempo, valence, and dance...
  let sum = 0;
  attributes.forEach((value, index) => {
    sum += value * weights[index];
  })

  return Math.round(((sum) + Number.EPSILON) * 100) / 100;
}

function getTrackAttributes(track: track): [SpotifyApi.AudioFeaturesObject, string[], artist[]] {
  const audioFeatures: SpotifyApi.AudioFeaturesObject = Playlist.AudioFeaturesMap.get(track.id) || empty_audio_features;
  let mergeGenres: string[] = [];
  let mergeRelatedArtists: artist[] = track.artists;
  track.artists.map(artist => {
    mergeGenres = [...mergeGenres, ...(Playlist.GenreMap.get(artist.id) || [])];
    mergeRelatedArtists = [...mergeRelatedArtists, ...(Playlist.RelatedArtistsMap.get(artist.id) || [])];
  })
  const genres: string[] = [...new Set(mergeGenres)];
  const uniqueSetRelatedArtists: Set<string> = new Set(mergeRelatedArtists.map(artist => JSON.stringify(artist)));
  const related_artists: artist[] = Array.from(uniqueSetRelatedArtists).map(artist => JSON.parse(artist));

  return [audioFeatures, genres, related_artists];
}