import { useAuth } from '../../Helpers';
import { Sequencer } from './Sequencer/Sequencer';

interface DashboardProps {
  code: string;
}
// "page" to handle getting a code upon sign-in
export default function Dashboard({ code }: DashboardProps) {
  const TOKEN = useAuth({code, endpoint:'/spotify'});

  return <Sequencer TOKEN={TOKEN}/>
}