export async function imageUrlToBase64(url: string): Promise<string> {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Invalid result type!'));
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

// // Usage example:
// const imageUrl = 'https://example.com/image.jpg';

// imageUrlToBase64(imageUrl)
//   .then((base64Uri) => {
//     console.log(base64Uri);
//   })
//   .catch((error) => {
//     console.error(error);
//   });
