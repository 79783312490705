const CLIENT_ID: string = "c66862b005ad477a9af8bfd78b3c7b3b";
const SPOTIFY_AUTHORIZE_ENDPOINT: string = 'https://accounts.spotify.com/authorize';
const REDIRECT_URI: string = (process.env.NODE_ENV === 'development') ? 'http://localhost:3000/spotify' : 'https://jackpauly.com/spotify';

const SEQ_SCOPES: string[] = [
  'playlist-modify-public',
  'playlist-modify-private',
  'playlist-read-private',
  'playlist-read-collaborative',
  'ugc-image-upload',
]
const SCOPES = SEQ_SCOPES.join('%20');
const AUTH_URL = `${SPOTIFY_AUTHORIZE_ENDPOINT}?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}`;

export default function Login() {
  return (
    <div className="container flex justify-center align-center h-screen items-center">
      <a href={AUTH_URL} className="bg-green-500 hover:bg-green-600 py-5 px-10 rounded-full text-xl">Login with Spotify</a>
    </div>
  )
}