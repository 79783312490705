import { FC } from 'react'
import ImageButtonCSS from '../css/ImageButton.module.css';

interface Props {
  link: string;
  path: string;
}

const ImageButton:FC<Props> = ( {link, path} ) => {
  return (
    <div>
      <a href={link} target="_blank" rel="noreferrer">
          <img className={ImageButtonCSS.image} src={path} alt="icon" />
      </a>
    </div>
  )
}

export default ImageButton