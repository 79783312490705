import { FC } from 'react'
import FooterCSS from '../css/Footer.module.css';

const Footer:FC = () => {
  return (
    <div className="container text-slate-200 text-xs" style={{display: "block"}}>
      <table className={FooterCSS.footerTable}>
        <tbody> 
          <tr>
            <td className="text-left"> © 2023 - Jack Marshall Pauly</td>
            <td className="text-right">JMP</td>
          </tr>
          <tr>
            <td className="text-left">Jack Pauly, <em>Spotify Sequencer</em>, are not related to Spotify AB or any of it's partners in any way</td>
            <td className="text-right">blehh</td>
          </tr>
          <tr>
            <td className="text-left">All music metadata associated with <em>Spotify Sequencer</em> provided by Spotify AB</td>
            <td className="text-right"></td>
          </tr>
      </tbody>
      </table>
    </div>
  )
}

export default Footer