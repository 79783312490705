import { FC } from 'react'
import BlogPreviewCSS from '../css/BlogPreview.module.css';

interface BlogPreviewProps {
    id: number;
    filename: string;
    slug: string;
    content: string;
}

const isAlphaNumeric = (str: string): boolean => {
    return /^[a-zA-Z0-9]+$/.test(str);
}

// Get the preview text for the blog.
const getBlogPreview = (content: string, maxLength: number):string => {
    const lines = content.split('\n');
    const paragraphs: string[] = [];

    for (let i=0; i<lines.length; ++i) {
        const line = lines[i].trim();
        if (line === '') {
            continue;
        } else if ( isAlphaNumeric(line.charAt(0)) ) {
            paragraphs.push(line);
        }
    }

    let prev = paragraphs.join('\n');
    
    if ( prev.length > maxLength-3) {
        prev = prev.substring(0, maxLength - 3)
        prev = prev.substring(0, prev.lastIndexOf(' '));
    }
    return prev + ' ...';
}

// Get the month number from the name of the month
const monthFromName = (mon: string) => {
    var d = Date.parse(`${mon} 1, 2023`);
    if (!isNaN(d)) {
        return new Date(d).getMonth() + 1;
    }
    return -1;
}

// Format the date
const getDate = (content: string): string => {
    var date = content.substring(content.indexOf('###') + 4);
    date = date.substring(0, date.indexOf('\n'));
    const month = monthFromName(date.substring(0, date.indexOf(' ')))
    date = `${month} ${date.substring(date.indexOf(' ') + 1)}`.replace(/[^0-9\s]/g,'');
    date = date.replaceAll(' ', '.');
    return date;
}

// Format the title
const getTitle = (content: string): string => {
    const regex = /[#*]/g;
    let title = content.substring(0, content.indexOf('\n'));
    title = title.replace(regex, '');
    return title;
}

const BlogPreview: FC<BlogPreviewProps> = ({ id, filename, slug, content }) => {
    const date = getDate(content);
    const title = getTitle(content);
    // console.log(title);
    const preview = getBlogPreview(content, 300);
    return (
        <a key={id} className={BlogPreviewCSS.block} href={`blog/${filename}`}>
            <div className={BlogPreviewCSS.blogDate}>
                {date}
            </div>
            <div className={BlogPreviewCSS.blogTitle}>
                {title}
            </div>
            <div className={BlogPreviewCSS.blogPreview}>
                {preview}
            </div>
            <br/>
        </a>
    )
}

export default BlogPreview;