import axios from 'axios';
import missing_texture from '../Images/missing_texture.png';
import { LastFMAlbum } from '../types';
const LASTFM_API_KEY = "467e1b2f66071487463b3b16aca803e3";

// const BASE_API_URL = 'https://ws.audioscrobbler.com/2.0/'
// HOLY FUCK IT WORKS OMG OMG OM GOM GOMGOGMOGM
// NOW JUST DO THIS FOR THE REST OF THEMMM
// I THINK YOU JUST HAVE TO DROP THE AXIOS SHIT. PATRICK WAS RIGHHTTT

export const searchAlbums2 = async (query: string) => {
    fetch(`https://ws.audioscrobbler.com/2.0/?method=album.search&album=${query}&api_key=${LASTFM_API_KEY}&format=json`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('error');
      })
      .then(data => console.log(data))
      .catch((error) =>
        console.error(error)
      );
}

export async function getUserInfo(username: string): Promise<boolean> {
    if (username === "") return false;
    try {
        axios.get('https://ws.audioscrobbler.com/2.0/', {
            params: {
                method: 'user.getInfo',
                user: username,
                api_key: LASTFM_API_KEY,
                format: 'json',
            },
        }).then((res) => {
            return true;
        }).catch((err) => {
            return false;
        })
        return true;
    } catch (error) {
        // console.error('Error occured while searching for user', error);
        console.log('user not found');
        return false;
        // throw error;
    }
}

export async function getTopAlbums(username: string, timeframe: string = 'overall'): Promise<any[]> {
    if (username === "") return [];
    try {
        const response = await axios.get('https://ws.audioscrobbler.com/2.0/', {
            params: {
                method: 'user.getTopAlbums',
                user: username,
                period: timeframe,
                api_key: LASTFM_API_KEY,
                format: 'json',
            },
        });
        return response.data.topalbums.album;
    } catch (error) {
        console.error('Error occured while getting top albums', error);
        throw error;
    }
}

export async function searchAlbums(query: string): Promise<any[]> {
    if (query === "") return [];
    try {
        const response = await axios.get('https://ws.audioscrobbler.com/2.0/', {
            params: {
                method: 'album.search',
                album: query,
                api_key: LASTFM_API_KEY,
                format: 'json',
            },
        });
        return response.data.results.albummatches.album;
    } catch (error) {
        console.error('Error occured while searching for albums', error);
        throw error;
    }
};

export const getInfo = async (title: string, artist: string) => {
    try {
        const response = await axios.get('https://ws.audioscrobbler.com/2.0/', {
            params: {
                method: 'album.getInfo',
                album: title,
                artist,
                api_key: LASTFM_API_KEY,
                format: 'json',
            },
        });
        return response.data.album;
    } catch (error) {
        console.error('Error occured while getting album info', error);
        throw error;
    }
}

export const getImage = async (title: string, artist: string): Promise<string> => {
    try {
        const results = await searchAlbums(`${title} ${artist}`);
        if (results.length === 0) {
            return missing_texture;
        } else {
            return results[0].image[3]['#text'];
        }
    } catch (error) {
        console.error('Error finding image', error);
        return missing_texture;
    }
}

export const hasImage = (album: LastFMAlbum): boolean => {
    try {
        if (album.image[album.image.length-1]['#text'] === '') {
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.error('Error finding image', error);
        return false;
    }
}