import {
  seq_track
} from '../types';
import {
  saveCSVToFile
} from '../Helpers';
import numeric from 'numeric';

// Define a sine regression function
const regressionFunction = (params: number[], x: number, data: regressionData): number => {
  let [A, C] = params;
  // A = (Math.abs(A) > absMax) ? absMax : A;
  return (A * Math.sin((2 * Math.PI / data.period) * (x + C)) + data.median);
};

let workingRegressionData: number[] = [];

// Function that calculates the best order based on the best, closest fitting
// sinusoidal regression function
export function regressionSequence(playlist: seq_track[]): seq_track[] {
  // Fixed it by...
  // Making the minimum temperature SUPER small (0.000000001 down from 0.001)
  // Making the temperature cooling rate "smaller" (decreases less each time)
  const optimal: seq_track[] = simulatedAnnealing<seq_track>(playlist, playlistScore, 1000, 0.99, 0.000000001, 10000);

  debug(optimal, playlist);

  return optimal;
}

function simulatedAnnealing<T>(
  playlist: T[],
  score: (playlist: T[]) => number,
  initialTemperature: number,
  coolingRate: number,
  minTemperature: number,
  maxIterations: number
): T[] {
  let currentPlaylist = playlist.slice();
  let temperature = initialTemperature;

  function shouldAccept(scoreCurrent: number, scoreNeighbor: number, temperature: number): boolean {
    if (scoreNeighbor < scoreCurrent) return true;
    else {
      const probability = Math.exp((scoreCurrent - scoreNeighbor) / temperature);
      return Math.random() < probability;
    }
  }

  for (let i=0; i<maxIterations && temperature > minTemperature; ++i) {
    const neighborPlaylist = genNeighbor(currentPlaylist);
    const scoreCurrent = score(currentPlaylist);
    // console.log(scoreCurrent); // to see if it is decreasing (IT IS!!! YAY)
    const scoreNeighbor = score(neighborPlaylist);

    if (shouldAccept(scoreCurrent, scoreNeighbor, temperature)) {
      currentPlaylist = neighborPlaylist;
    }

    temperature *= coolingRate;
  }
  return currentPlaylist;
}

// sineRegression
// Returns the cost of the calculated sine regression (lower is better)
function regression(playlist: seq_track[]): number {
  // get the energies to get concrete regression Data
  const energyArr: number[] = playlist.map(track => track.audio_score);
  const regressionData = getPermutationData(energyArr);

  const initialParams = [-1, 0];

  // This behaves kinda like the predict function.
  // Lower score is better.
  const costFunction = (params: number[]): number => {
    const residuals = playlist.map((track, index) => (track.audio_score - regressionFunction(params, index, regressionData)));
    const cost = numeric.norm2(residuals)
    return cost;
  };

  // Does ...something... then gets the best fitting params
  const fittedParams = numeric.uncmin(costFunction, initialParams).solution;
  workingRegressionData = fittedParams;
  return costFunction(fittedParams);
}

// Cost function for calculating how similar neighbors are
function neighborSimilarity(playlist: seq_track[]): number {
  let similarities: number[] = [];
  let numBlanks: number = 0;
  for (let i=0; i<playlist.length-1; ++i) {
    const sim: number = calculateSongSimilarity(playlist[i], playlist[i+1]);
    // console.log(`${playlist[i].title} & ${playlist[i+1].title}: ${sim}`);
    similarities.push(sim);
    if (sim === 0) {
      ++numBlanks;
    }
  }
  return (numBlanks / (playlist.length - 1));
}

// The "score" of a playlist, calculated using the weighted regression and neighbor similarity scores
// Lower score is better. Want to achieve lower score.
// Change the weights to alter if I want a playlist with more focus on the regression or the neighbor similarity 
const REGRESSION_WEIGHT: number = 0.6;
const NEIGHBOR_SIMILARITY_WEIGHT: number = 0.4;
function playlistScore(playlist: seq_track[]) {
  return (regression(playlist) * REGRESSION_WEIGHT) + (neighborSimilarity(playlist) * NEIGHBOR_SIMILARITY_WEIGHT);
}

// aux functions

// genre Jaccard similarity
function calculateSongSimilarity(song1: seq_track, song2: seq_track): number {
  if (song1.artists[0] === song2.artists[0] ) return 0;
  const genres1 = new Set(song1.genres);
  const genres2 = new Set(song2.genres);
  const related1 = new Set(song1.related_artists);
  const related2 = new Set(song2.related_artists);
  // ^^ check this for set mismatch

  const genreIntersectionSize: number = [...genres1].filter(genre => genres2.has(genre)).length;
  const genreUnionSize: number = genres1.size + genres2.size - genreIntersectionSize;
  const genreScore = (genreIntersectionSize / genreUnionSize);
  
  const relatedIntersectionSize: number = [...related1].filter(related => related2.has(related)).length;
  const relatedUnionSize: number = related1.size + related2.size - relatedIntersectionSize;
  const relatedScore = (relatedIntersectionSize / relatedUnionSize);

  return (genreScore * 0.6) + (relatedScore * 0.4);
}

// Function to generate a neighbor of an array
function genNeighbor<T>(arr: T[]): T[] {
  const neighbor = arr.slice();
  const i = Math.floor(Math.random() * neighbor.length);
  const j = Math.floor(Math.random() * neighbor.length);
  [neighbor[i], neighbor[j]] = [neighbor[j], neighbor[i]]; // Swap two elements
  return neighbor;
}

interface regressionData {
  mean: number;
  median: number;
  absMax: number;
  period: number;
  amplitude: number;
}

function getPermutationData(arr: number[]): regressionData {
  const mean = getMean(arr);
  const median = getMedian(arr);
  const [max, min] = [
    Math.max(...arr),
    Math.min(...arr)
  ];
  const absMax = Math.max(Math.abs(min), max);
  // Period, calculated by the cubic root of the length
  const period = arr.length / (Math.pow(arr.length, (1/3)));

  return {
    mean: mean,
    median: median,
    absMax: absMax,
    period: period,
    amplitude: (absMax - median),
  }
}

const getMean = (arr: number[]) => {
  return arr.reduce((prev, curr) => {
    return prev + curr;
  }, 0) / arr.length;
}

const getMedian = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

function debug(optimal: seq_track[], playlist: seq_track[]): void {
  const [A, C] = workingRegressionData;
  const energyArr: number[] = playlist.map(track => track.audio_score);
  const regressionData = getPermutationData(energyArr);
  const [B, D] = [regressionData.period, regressionData.median];
  const rows = optimal.map((track, index) => [index, track.audio_score]);
  
  let csvContent = "Index, Energy\n"
  + rows.map(e => e.join(",")).join("\n");
  
  console.log(`A: ${A}\nB: ${B}\nC: ${C}\nD: ${D}`);
  console.log(csvContent);
  
  // saveCSVToFile(csvContent, `log-${new Date().toString()}`);
}