import * as spotify from './spotify';

// A map that maps the artist ID to their associated genres
// export const seq_Artist_genres = new Map<string, string[]>();

export interface seq_track extends spotify.track {
  audio_features: SpotifyApi.AudioFeaturesObject;
  audio_score: number;
  related_artists: spotify.artist[];
  genres: string[];
}

export const empty_audio_features: SpotifyApi.AudioFeaturesObject = {
  acousticness: 0.5,
  analysis_url: '',
  danceability: 0.5,
  duration_ms: 0,
  energy: 0.5,
  id: '',
  instrumentalness: 0.5,
  key: 0,
  liveness: 0.5,
  loudness: 0.5,
  mode: 1,
  speechiness: 0.5,
  tempo: 100,
  time_signature: 0,
  track_href: '',
  type: 'audio_features',
  uri: '',
  valence: 0.5
}

export const seq_empty_track = {
  ...spotify.empty_track,
  local_file: true,
  audio_features: empty_audio_features
}

export interface seq_playlist extends spotify.playlist {
  tracks: seq_track[],
  dataget: boolean
}

export const seq_empty_playlist = {
  ...spotify.empty_playlist,
  tracks: [],
  dataget: false,
}

export interface seq_user extends spotify.user {
  playlists: spotify.playlist[]
  num_playlists: number
}

export const seq_empty_user = {
  ...spotify.empty_user,
  playlists: [],
  num_playlists: 0
}