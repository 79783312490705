import { FC, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import BlogCSS from '../css/Blog.module.css';
import blogPosts from '../blogList.json';
import BlogPostPage from './BlogPostPage';
import BlogPreview from '../Components/BlogPreview';
import { BlogFile } from '../types/interfaces';

// test
// const test = async () => {
//   const resp = await fetch('/blogs/test.txt');
//   const content = await resp.text();
//   console.log(content);
// };

const Blog:FC = () => {
  const location = useLocation();

  const [blogs, setBlogContents] = useState<BlogFile[]>([]);
  
  // Create the new blog posts
  useEffect(() => {
    const fetchBlogFiles = async () => {
      const filePromises = blogPosts.map(async (filePath: string, index:number) => {
        const response = await fetch(filePath);
        const id = index;
        const slug = filePath.substring(filePath.lastIndexOf('/') + 6, filePath.lastIndexOf('.md'));
        const filename = `${slug.replace(/\s+/g, '-').toLowerCase()}`;
        const content = await response.text();
        const element = <BlogPostPage markdownContent={content}/>
        return { id, slug, filename, content, element};
      });

      const files = await Promise.all(filePromises);
      setBlogContents(files);
    };

    fetchBlogFiles();
  }, []);

  // test();

  const NewRoutes:FC = () => {
    return (
      <Routes>
        {blogs.map((page) => (
          <Route key={page.id} path={page.filename} element={page.element} />
        ))}
      </Routes>
    )
  };

  const reversed = [...blogs].reverse();

  return (
    <div className={BlogCSS.blog_container}>
      {location.pathname === '/blog' &&
        <div className={BlogCSS.blog_links}>
          {reversed.map((page) => {
            return (
              <BlogPreview key={page.id} id={page.id} filename={page.filename} slug={page.slug} content={page.content}/>
            )
          })}
        </div>
      }
      <NewRoutes />
    </div>
  )
}

export default Blog;
