import Dashboard from '../Components/Spotify/Dashboard';
// import Cookies from 'js-cookie';
// import { Sequencer } from '../Components/Spotify/Sequencer/Sequencer';
import Login from '../Components/Spotify/Login';

const code = new URLSearchParams(window.location.search).get('code');

export default function Spotify() {

  if (code) {
    return <Dashboard code={code}/>
  } else {
    return <Login/>
  }
}
