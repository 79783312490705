import { Album } from '../../types';
import DisplayAlbumCSS from '../../css/DisplayAlbum.module.css';

interface displayAlbumListProps {
  albumList: Album[];
  removeAlbum: (album:Album) => void;
}

export function DisplayAlbumList({albumList, removeAlbum}: displayAlbumListProps) {
  const sorted = albumList.sort((a, b) => {
    return b.ELO - a.ELO;
  })
  return (
  <div className="bg-slate-100 relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead>
        <tr className="bg-gray-50">
          <th scope="col">#</th>
          <th scope="col">ELO</th>
          <th scope="col">Cover</th>
          <th scope="col">Name</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {sorted.map((album, index) => (
          <tr key={index} className="even:bg-gray-50 odd:bg-white">
            <td>{index+1}</td>
            <td>{album.ELO.toFixed(0)}</td>
            <td className={DisplayAlbumCSS.album_cover} style={{
              backgroundImage: `url(${album.coverURL})`,
              width: '64px',
              height: '64px',
              borderRadius: '5px'
            }} />
            <td>
              <div className="ml-3">
                <div>{album.title}</div>
                <div className="text-gray-400">{album.artist}</div>
              </div>
            </td>
            <td className="text-center">
              <button className="bg-red-500 hover:bg-red-700 py-1 px-3 rounded text-white" onClick={(() => removeAlbum(album))}>
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}