/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, useReducer } from 'react'
import SpotifyWebApi from 'spotify-web-api-node';
import {
  playlist,
  empty_playlist,
  seq_user,
  seq_empty_user,
} from '../../../types';
import {
  Playlist,
  PlaylistObjectSimplifiedToPlaylist,
  largestAlbumImageUrl
} from '../../../Helpers';
import PlaylistSearchResult from './PlaylistSearchResult';
import PlaylistTrack from './PlaylistTrack';
import { SaveModal, InfoModal } from '../../Modal';
import spotifyLogo from '../../../Images/Spotify_Icon_RGB_Black.png';


interface SequencerProps {
  TOKEN: string | undefined
}

const spotifyApi = new SpotifyWebApi({
  clientId: 'c66862b005ad477a9af8bfd78b3c7b3b'
});

const initState = {
  sequenceButtonClicked: false,
  saveButtonClicked: false,
  search: "",
  pageNum: 0,
  pageLoaded: false,
  fetchLoaded: true,
  saveModalActive: false,
  infoModalActive: false,
}

type ReducerAction = 
 | {type: 'SETSEQUENCEBTN'; payload: boolean}
 | {type: 'SETSAVEBTN'; payload: boolean}
 | {type: 'SETSEARCHBAR'; payload: string}
 | {type: 'SETPAGENUM'; payload: number}
 | {type: 'SETPAGELOADED'; payload: boolean}
 | {type: 'SETFETCHLOADED'; payload: boolean}
 | {type: 'SETSAVEMODAL'; payload: boolean}
 | {type: 'SETINFOMODAL'; payload: boolean}

const reducer = (state: typeof initState, action: ReducerAction): typeof initState => {
  switch (action.type) {
    case 'SETSEQUENCEBTN':
      return { ...state, sequenceButtonClicked: action.payload}
    case 'SETSAVEBTN':
      return { ...state, saveButtonClicked: action.payload}
    case 'SETSEARCHBAR':
      return { ...state, search: action.payload}
    case 'SETPAGENUM':
      return { ...state, pageNum: action.payload}
    case 'SETPAGELOADED':
      return { ...state, pageLoaded: action.payload}
    case 'SETFETCHLOADED':
      return { ...state, fetchLoaded: action.payload}
    case 'SETSAVEMODAL':
      return { ...state, saveModalActive: action.payload}
    case 'SETINFOMODAL':
      return { ...state, infoModalActive: action.payload}
    default:
      return state
  }
}

// Function to get the user
async function fetchUser(): Promise<SpotifyApi.CurrentUsersProfileResponse> {
  const user = ((await spotifyApi.getMe()).body);
  return user;
}

// Function to get the user playlists given a limit and offset
async function fetchUserPlaylists({limit = 50, offset = 0}): Promise<SpotifyApi.ListOfUsersPlaylistsResponse> {
  const playlists = (await spotifyApi.getUserPlaylists({limit, offset})).body;
  console.log(`got playlists ${limit+offset} to ${limit}`);
  return playlists;
}

async function fetchAllUsersPlaylists(): Promise<playlist[]> {
  let playlists: playlist[] = [];
  // console.log(`userID: ${userId}\nAccessToken: ${spotifyApi.getAccessToken()}`);

  const fetchedPlaylists = (await fetchUserPlaylists({limit: 50, offset: 0}));
  fetchedPlaylists.items.map((pl) => (
    playlists.push(PlaylistObjectSimplifiedToPlaylist(pl))
  ))
  const total = fetchedPlaylists.total;
  for (let i=50; i<total; i+=50) {
    const fetchedPlaylists = (await fetchUserPlaylists({limit: 50, offset: i})).items;
    fetchedPlaylists.map((pl) => (
      playlists.push(PlaylistObjectSimplifiedToPlaylist(pl))
    ))
  }
  console.log('got all playlists');
  return playlists;
}

export const Sequencer = ({ TOKEN }: SequencerProps) => {
  const [userInfo, setUserInfo] = useState<seq_user>(seq_empty_user);
  const [playlistObject, setPlaylistObject] = useState<Playlist>(new Playlist(spotifyApi, empty_playlist));
  const [playlistDisplay, setPlaylistDisplay] = useState<playlist>(empty_playlist);
  const [filteredPlaylists, setFilteredPlaylists] = useState<playlist[]>([]);
  const [state, dispatch] = useReducer(reducer, initState); // for simple state stuff

  useEffect(() => {
    if (TOKEN) {
      spotifyApi.setAccessToken(TOKEN);
    }
  }, [TOKEN])

  function savePlaylist() {
    dispatch({ type: "SETSAVEMODAL", payload: false });
    dispatch({ type: "SETSAVEBTN", payload: true });
    playlistObject.savePlaylist(); // save the playlist
  }

  function closeModal() {
    dispatch({ type: "SETSAVEMODAL", payload: false });
    dispatch({ type: "SETINFOMODAL", payload: false });
  }

  // Button logic for selecting a playlist
  async function choosePlaylist(playlist: playlist) {
    if (playlist.id === "") return;
    if (!TOKEN) return;
    
    const new_playlist_object = new Playlist(spotifyApi, playlist);
    dispatch({ type: "SETFETCHLOADED", payload: false });
    await new_playlist_object.fetchPlaylist()
    dispatch({ type: "SETFETCHLOADED", payload: true });
    setPlaylistDisplay(new_playlist_object.getPlaylist());
    setPlaylistObject(new_playlist_object); // Set the playlist object to this newly created one...
  }
  
  // Button logic for sequencing a playlist
  async function onSequenceButton() {
    dispatch({ type: "SETSEQUENCEBTN", payload: true });
    dispatch({ type: "SETSAVEBTN", payload: false });
    dispatch({ type: "SETFETCHLOADED", payload: false });
    // Need this badness for some odd reason just so the display of tracks update
    await playlistObject.sequencePlaylist()
    setPlaylistDisplay(playlistObject.getSequenced());
    
    dispatch({ type: "SETFETCHLOADED", payload: true });
  }

  // Button logic for back button
  function onBackButton() {
    dispatch({ type: "SETSEQUENCEBTN", payload: false });
    dispatch({ type: "SETSAVEBTN", payload: false });
    dispatch({ type: "SETSEARCHBAR", payload: "" });
    setPlaylistObject(new Playlist(spotifyApi, empty_playlist));
    setPlaylistDisplay(empty_playlist);
  }
  
  // Button logic for saving a playlist
  function onSaveButton() {
    dispatch({ type: "SETSAVEMODAL", payload: true });
  }
  
  // Button logic for the info modal
  function onInfoButton() {
    dispatch({ type: "SETINFOMODAL", payload: true });
  }

  function onPageButton(page: number) {
    dispatch({ type: "SETPAGENUM", payload: page });
  }

  // useEffect on Login
  useEffect(() => {
    if (!TOKEN) return;
    dispatch({ type: "SETPAGELOADED", payload: false });
    // Get the basic user info
    fetchUser().then(user => {
      fetchAllUsersPlaylists().then(playlists => {
        setUserInfo({
          ...userInfo,
          id: user.id,
          display_name: user.display_name,
          uri: user.uri,
          href: user.href,
          image_url: user.images ? largestAlbumImageUrl(user.images) : "",
          num_playlists: playlists.length,
          playlists,
          external_url: user.external_urls.spotify,
        })
        dispatch({ type: "SETPAGELOADED", payload: true });
        setFilteredPlaylists(playlists);
      })
    })
  }, [TOKEN]);

  // useEffect on search change
  useEffect(() => {
    // console.log('here')
    setFilteredPlaylists(userInfo.playlists.filter(pl => pl.name.toLowerCase().includes((state.search).toLowerCase())))
    dispatch({ type: "SETPAGENUM", payload: 0 }) // set the page num to 0
  }, [state.search])

  const Spinner:FC = () => {
    return (
      <div>
        <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-300 rounded-full" role="status" aria-label="loading">
          <span className="sr-only">
            Loading...
          </span>
        </div>
      </div>
    )
  }

  const Pagination = ({pageArr}: {pageArr: number[]}) => {
    return (
      <div className="flex items-center space-x-1">
        <button
          className={`px-2 py-1 rounded ${
            state.pageNum === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'
          }`}
          onClick={() => onPageButton(state.pageNum - 1)}
          disabled={state.pageNum === 0}
        >
          {`<`}
        </button>
        {pageArr.map((value, index) => (
          <button
            key={index}
            className={`px-2 py-1 rounded ${
              state.pageNum === value ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'
            }`}
            onClick={() => onPageButton(value)}
            disabled={state.pageNum === value}
          >
            {value + 1}
          </button>
        ))}
        <button
          className={`px-2 py-1 rounded ${
            state.pageNum === pageArr.length - 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'
          }`}
          onClick={() => onPageButton(state.pageNum + 1)}
          disabled={state.pageNum === pageArr.length - 1}
        >
          {`>`}
        </button>
      </div>
    )
  }

  // Code for the header that always stays there
  const Header:FC = () => {
    return (
      <>
        <div className="flex justify-between my-2">
          <button
          className={`py-1 px-4 rounded-full ${playlistDisplay.id === "" ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'}`}
          onClick={onBackButton}
          disabled={playlistDisplay.id === ""}
          >
            Back
          </button>
          <button
          className={`py-1 px-4 rounded-full bg-blue-500 hover:bg-blue-700`}
          onClick={onInfoButton}
          >
            About
          </button>
        </div>
        <table className="table-fixed w-full">
          <tbody>
            <tr>
              <td className="w-16">
                <img src={userInfo.image_url} style={{height: '64px',width: '64px', marginRight: '10px'}} alt='profile pic' />
              </td>
              <td className="w-10/12 truncate" style={{display: "block"}}>
                <div className="ml-1" style={{marginRight: '10px'}}>
                  <div>{userInfo.display_name}</div>
                  <div className="text-gray-400">{`Playlists: ${userInfo.num_playlists}`}</div>
                </div>
              </td>
              <td className="w-1/12">
                <a href={userInfo.external_url} target="_blank" rel="noreferrer">
                  <img src={spotifyLogo} style={{ height: '24px',width: '24px', marginRight: '10px'}} alt="spotify logo" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>  
        <div>
          {(!state.fetchLoaded && playlistDisplay.id==="") && (<><Spinner/>Fetching Tracks...</>)}
        </div>
      </>
    )
  }

  const PlaylistSelect:FC = () => {
    const pageArr: number[] = Array.from({length: Math.ceil(filteredPlaylists.length / 50)}, (item, index) => index)
    const displayedPlaylists = filteredPlaylists.slice(state.pageNum * 50, 50 * (state.pageNum+1));
    return (<>
      <div id="playlists" className="flex-grow-1 my-2 overflow-y-auto">
        {displayedPlaylists.map((pl, index) => (
          <PlaylistSearchResult key={index} playlist={pl} choosePlaylist={choosePlaylist}/>
          ))}
      </div>
      <div className="flex justify-center">
        <Pagination pageArr={pageArr}/>
      </div>
    </>)
  }

  const PlaylistPage:FC = () => {
    return (
      <>
        <div className="my-2">
          <PlaylistSearchResult playlist={playlistDisplay} choosePlaylist={() => {return}} />
        </div>
          <div className="flex justify-between my-2">
            <button
            className='bg-green-500 hover:bg-green-700 py-2 px-4 rounded-full'
            onClick={() => {
              onSequenceButton();
            }}>
              {state.fetchLoaded ? (
                <>{state.sequenceButtonClicked ? 'Re-Sequence' : 'Sequence'}</>
                ) : (
                <Spinner/>
              )}
            </button>
            {!state.fetchLoaded && <div>please wait!</div>}
            {(state.sequenceButtonClicked && state.fetchLoaded)? 
              <button
                className={`py-2 px-4 rounded-full ${state.saveButtonClicked || !state.fetchLoaded ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500 hover:bg-green-700'}`}
                disabled={state.saveButtonClicked || !state.fetchLoaded}
                onClick={() => onSaveButton()}>Save Playlist?</button> 
            : <></>}
          </div>
        <div id="tracks" className="flex-grow-1 my-2 overflow-y-auto">
          <div className="">
            {playlistDisplay.tracks.map((track, index) => (
              <PlaylistTrack key={index} index={index} track={track}/>
              ))}
          </div>
        </div>
      </>
    )
  }

  if (!state.pageLoaded) {
    return (
      <div className="bg-gray-600 ">
        <div style={{
          display: "flex",
          alignItems: "stretch",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.2)"
        }}>
          <Spinner/>
          Loading Playlists... 
        </div>
      </div>
    )
  }
  return (
    <div className="bg-gray-600 p-4">
      {state.saveModalActive && <SaveModal closeModal={closeModal} savePlaylist={savePlaylist}/>}
      {state.infoModalActive && <InfoModal closeModal={closeModal}/>}
      {TOKEN && 
        <div className="container flex flex-col" style={{ height: "100vh" }}>
          <Header/>
          {(playlistDisplay.id === "") ? <>
              <input
                className="shadow appearance-none border rounded w-5/6 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="search"
                placeholder="Search Playlists"
                value={state.search}
                onChange={e => dispatch({ type: "SETSEARCHBAR", payload: e.target.value })}
              />
            <PlaylistSelect/>
          </> : <PlaylistPage/> }
        </div>}
    </div>
  )
}
/*
PROGRESS 8.22.23
  Got basic playlist sequencing to work
TODO:
  - maybe have a check for repeated artists? Maybe add as an option
  - genre check?? prefer songs with artists that have similar genre?
    - ^ this might be too taxing on the api. Would require a lot of api calls
    - ^^ throw a hashmap at this lol. Hash the artists and the associated genres so you don't have to keep re-calling the api for the same artist. It would reduce api calls, but the problem still remains
  - find some way to incorporate boolean attributes into the similarity algorithm??? maybe binary 0 and 1's?? not sure yet 
  - fix/ re-make home screen for the spotify-api projects page on your site. <-- requires headbashing css though because i'm terrible at design.
PROGRESS: 8.29.23
  Successfully migrated the playlist object to its own file
  Interacting with it + the sequenced copy of it is a lot easier (for the most part, ughhh)
  Notes: Longer playlist take a LOOOOONG time to load
  this is mainly because of how many API calls I need to make
TODO:
  Try for fewer API calls (or) separate the calls to reduce load time
  Figure out a loading screen for when the playlist is loading
  OR: (better yet) move all intensive API calls to the "sequence" button, that way, when the user clicks "sequence", the app can THEN spin.
  Users are more likely to accept loading in terms of an algorithm spinning than loading a webpage...

PROGRESS 8.31.23
  Cleaned up a lot of code.
  Altered the playlist.ts script so that the playlist object is the base playlist and the "sequenced" object is a sequenced_playlist with all the yummy data
  Also added a spinner to indicate loading. Very helpful.
  Still don't know why the webpage doesn't load sometimes on first login. Need more testing
TODO:
  Implement that better algorithm with genres, related artists, and other track attributes

PROGRESS 9.5.23
  Cleaned up loading synchronization. Added a page navigator to access all playlists
  Attempted to replace useState with useReducer, but failed to make a dent.
  ^^ issues caused by race conditions in loading, not reducer itself. for my project, however, useState might be the way to go since it's not being a problem rn
TODO:
  Implement that better algorithm with genres, related artists, etc.
  Find a way to order the tracks using both heuristics.
  Find out how to do persistent login!!! use the localstorage and shit

PROGRESS 9.6.23
  Figured out how to use cookies and do a persistent login
TODO
  Implement that algorithm with the heuristics
  Make the search bar to search through playlists

IDEA:
  Maybe make a tool that shows you who follows your playlists? 👀

PROGRESS 9.7.23
  Spun around in circles trying to get this damn authentication thing working ughhh i hate myself
  After i went back to square one, I started working on the search bar
TODO:
  You need to create *something* that details WHAT is displayed on screen
  ^^ this has to be a combination of (in order)
  1. the playlists left after the filter
  2. how many playlists are left
  3. ^ this determines a.) how many pages there are and b.) what page the user is currently on

PROGRESS 9.11.23
  Fxck cookies, fxck server-side databases, fxck refresh tokens. we're doing this the old fashioned way:
  Sign in whenever you get to the screen. end of story
  finished the "what" that's displayed on screen with filters, etc
  Compromised on the useState/ useReducer. Implemented useReducer for *some* values

PROGRESS 9.12.23
  Okay, I finally got the chance to take a crack at these heuristics. Finally making progress on that front
  ChatGPT and I came to a solution where, essentially I'm left with an optimization problem.
  I need to maximize (minimize) similarity to a regression of my choice (linear, sine, etc)
  and I need to maximize (minimize) similarity from one track to its neighbors
  However, I have no idea where to start when it comes to optimization algorithms. Not sure where I go from here.
  Another thing to note is that, if I *always* value linearity over neighbor familiarity, then I'm going to end up with a line from least energetic song to most energetic song *every time*.
  ^ On the other hand, if I calculate regression on a sine wave, then I'm getting somewhere, since it has both ups and downs and places I can optimize. Sine wave is hard though, because I'm not sure how I can even create a sinusoidal regression. Maybe ask ChatGPT this...
TODO:
  Clean up code, the sequencer.ts file is very bulky.
  figure out sinusoidal regression, if possible.
  Figure out how to do Optimization algorithms at all in javascript.

PROGRESS 9.13.23
  I feel great! I just made some hefty progress on the sine regression
  I figured out how to generate a sine regression and in the process, almost figured out how to
  solve my optimization problem since the sine regression uses an optimization algorithm.
TODO:
  Keep working on this. Look into "Simulated Annealing" as a way to sort a giant set of data.

PROGRESS 9.14.23
  IT WORKED OMG OMG OMG OMG
  I used simulated annealing and holy fxcking shit it works
  Okay i'll slow down a bit, it works for very small playlists, like 10 songs long, but STILL
  The issue I'm runnning into now is that, if left to its own devices, when trying to find the Sinusoidal Regression with the best fit, the algorithm will often resort to a really boring, tiny sine wave when that's NOT what I want lol.
  To fix this, I need to hard program some rules for the sine wave, such as minimum amplitude and sert a period based on how playlist length. STILL very exciting things are about. yayayayayayay
TODO:
  Look into programming in those rules as previously talked about
  maybe:
  - integrate the other attributes into the score? not just energy? not sure about ths yet. Will need to look into using two heuristics
  - find some way, maybe write a python program, to visualize the data and the correctness of the graph. Try google collab.
  - Maybe need to increase the maxIterations of the simulation to get a better regression

PROGRESS 9.20.23
  Good progress on converting from bootstrap to tailwind.
TODO:
  - Buttons on the sequencer page
  - spacing with images
  - margins with text boxes, etc
  - displaying the playlist on the playlist page (after selected)

PROGRESS 9.21.23
  Cleaned up the sequencer code, finished porting to tailwind
TODO:
  - Follow the tutorial in Watch Later to make a modal/popup dialog box for when the user wants to save their playlist

PROGRESS 9.25.23
  Added the modal/popup for when the user is saving a playlist
TODO:
  - Right now, the sinusoidal regression works, it just doesn't make a lot of sense.
  - Need to add another heuristic to tell if adjacent songs fit each other, but how?? Idk.

PROGRESS 9.26.23
  - Added a second heuristic. Works... works okay. Not bad at all. I am still able to maintain a nice sinusoidal curve, even across large playlists.
  - Added an info panel
TODO:
  - get a fxcking job, hippie. You need to work on LEADS my boy.
  - Reach out to Prasad
  - Look through some job boards, find a job and really apply. It'll make you feel better, I promise.
  - Look into fixing the loading problem with the playlist ughhh.
*/