import React from 'react'
import { Album } from '../../types'

interface AlbumSearchResultProps {
  album: Album
  chooseAlbum: (album: Album) => void
}

export default function AlbumSearchResult({album, chooseAlbum}: AlbumSearchResultProps) {

  function handleAdd() {
    chooseAlbum(album);
  }

  return (
    <div className="flex m-2 items-center"
      style={{ cursor: "pointer" }}
      onClick={handleAdd}
    >
      <img src={album.coverURL} style={{height: '64px',width: '64px'}} alt={album.title} />
      <div className="ml-3">
        <div>{album.title}</div>
        <div className="text-gray-400">{album.artist}</div>
      </div>
    </div>
  )
}
