import React from 'react'
import ModalCSS from '../css/modal.module.css';
import sequencer_example from '../Images/sequence_example.png';

interface SaveModalProps {
  closeModal: () => void;
  savePlaylist: () => void
}
interface InfoModalProps {
  closeModal: () => void;
}

export function SaveModal({ closeModal, savePlaylist }: SaveModalProps) {
  return (
    <div className={ModalCSS.modalBackground}>
      <div className={ModalCSS.modalContainer}>
        <button
          className="bg-red-500 hover:bg-red-700 py-1 px-2 rounded"
          onClick={()=> closeModal()}
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            cursor: "pointer"
          }}>
          &times;
        </button>
        <div className={ModalCSS.title}>
          <h1>Save Playlist to Library?</h1>
        </div>
        <div className={ModalCSS.body}>
          Note: Playlists cannot be deleted <em>on this website</em>. Delete them on the Spotify App
        </div>
        <div className={ModalCSS.footer}>
          <button
            className="bg-red-500 hover:bg-red-700 py-2 px-4 rounded-full"
            onClick={() => closeModal()}>
            Cancel
          </button>
          <button
            className="bg-green-500 hover:bg-green-700 py-2 px-4 rounded-full"
            onClick={() => savePlaylist()}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export function InfoModal({ closeModal }: InfoModalProps) {
  return (
    <div className={ModalCSS.modalBackground}>
      <div className={ModalCSS.modalContainer} style={{height: "auto"}}>
        <button
          className="bg-red-500 hover:bg-red-700 py-1 px-2 rounded"
          onClick={()=> closeModal()}
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            cursor: "pointer"
          }}>
          &times;
        </button>
        <div className={ModalCSS.title}>
          <h1>How it works!</h1>
        </div>
        <div className={ModalCSS.body} style={{maxWidth: "80vh"}}>
          The <em>Playlist Sequencer</em> analyzes the attributes of each song in your playlist (like tempo, energy, danceability, etc), and orders them so that your playlist has ups and downs in energy. Below is a graph showing tracks and their energy level on a sequenced playlist. The sequencer works best on medium sized playlists of similar-ish songs!
          <br/>NOTE: Longer playlists take some time to load so be patient! :3
          <br/>
          <div className="">
            <img
              className="object-scale-down h-60 ml-auto mr-auto"
              src={sequencer_example}
              alt="example graph of a sequenced playlist"
            />
          </div>
          <div className="text-xs text-gray-500">All song, user, and playlist data provided by Spotify AB with permissions from Spotify's Developer portal</div>
        </div>
      </div>
    </div>
  )
}