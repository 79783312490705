import {
  playlist,
  seq_playlist,
  seq_track
} from '../types';
import { regressionSequence as sineReg} from './sineReg';

// Map of the artists' ids to their associated genres
export const genreMap = new Map<string, string[]>();

// main function that will take in a playlist, and output a sequenced playlist
export const sequence = (playlist: seq_playlist, origin_playlist: playlist): seq_playlist => {
  const tracks: seq_track[] = playlist.tracks;
  // If the playlist (minus local files) is empty, then just return the playlist and don't sequence
  if (tracks.length === 0) return playlist;
  
  // console.log(tracks);

  // Find the playlist with the best sine regression
  const sequenced_playlist = sineReg(tracks);

  return {
    ...origin_playlist,
    name: `[sequenced] ${origin_playlist.name}`.substring(0, 100),
    description: `${origin_playlist.name}, sequenced with jackpauly.com/spotify`,
    tracks: sequenced_playlist,
    dataget: true,
  }
}