import { track } from '../../../types';
import spotifyLogo from '../../../Images/Spotify_Icon_RGB_Black.png';

interface PlaylistTrackProps {
  index: number;
  track: track;
}

const PlaylistTrack = ({ index, track }: PlaylistTrackProps) => {
  return (
    <table className="table-fixed w-full">
        <tbody>
          <tr>
            <td className="w-16">
              <img src={track.cover_url} style={{height: '64px',width: '64px'}} alt={track.title} />
            </td>
            <td className="w-10/12 truncate" style={{display: "block"}}>
              <div className="ml-1">
                <div className="truncate">{track.title}</div>
                <div className="text-gray-400 truncate">{track.artists.map(artist => artist.name).join(', ')}</div>
              </div>
            </td>
            <td className="w-1/12">
              <a href={track.external_url} target="_blank" rel="noreferrer">
                <img src={spotifyLogo} style={{ height: '24px',width: '24px', marginRight: '10px'}} alt="spotify logo" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
  );

  return (
    <div className="flex items-center mb-2" style={{ cursor: "pointer" }}>
      <div className="mr-1" style={{minWidth: '30px'}}>
        <h3>{index+1}</h3>
      </div>
      <div className="flex truncate">
        <img src={track.cover_url} style={{ height: '50px', width: '50px', marginRight: '10px' }} alt={track.title} />
        <div className="ml-1">
          <div className="truncate">{track.title}</div>
          <div className="text-gray-400 truncate">{track.artists.map(artist => artist.name).join(', ')}</div>
        </div>
      </div>
    </div>
  )
}

export default PlaylistTrack;