import React, { useEffect, useState }from 'react'
import { Album } from '../../types';
import { getUserInfo, getTopAlbums, ELO_INITAL_RATING } from '../../Helpers'
import { DisplayAlbumList } from './DisplayAlbum';
import AlbumContest from './AlbumContest';
import missing_texture from '../../Images/missing_texture.png';

export default function LastFMSort() {
  const [username, setUsername] = useState("");
  const [validUser, setValidUser] = useState(false);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("");
  const [albumList, setAlbumList] = useState<Album[]>([]);

  useEffect(() => {
    if((username === "" && selectedTimeFrame === "")) return;
    localStorage.setItem(`lastfm_albumlist_${username}_${selectedTimeFrame}`, JSON.stringify({
      username: username,
      timeframe: selectedTimeFrame,
      albums: albumList
    }));
  }, [albumList])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!await getUserInfo(username)) {
      setValidUser(false);
      setSelectedTimeFrame("");
      return;
    }

    setValidUser(true);
    setSelectedTimeFrame("");
  }

  const handleTimePeriodClick = async (timeframe: string) => {
    setSelectedTimeFrame(timeframe);
    if(validUser) {
      const storedLFMAlbumList = localStorage.getItem(`lastfm_albumlist_${username}_${timeframe}`);
      if (storedLFMAlbumList) {
        setAlbumList(JSON.parse(storedLFMAlbumList).albums);
      } else {
        getTopAlbums(username, timeframe).then((res) => {
          setAlbumList(
            res.map((album) => {
              return {
                id: (album.artist.name+"_"+album.name),
                title: album.name,
                artist: album.artist.name,
                ELO: ELO_INITAL_RATING,
                coverURL: (album.image[album.image.length-1]['#text'] === "" ? missing_texture : album.image[album.image.length-1]['#text'])
              }
            })
            );
        })
      }
    }
  }

  const handleRefreshChart = async () => {
    getTopAlbums(username, selectedTimeFrame).then((res) => {
      setAlbumList(
        res.map((album) => {
          return {
            id: (album.artist.name+"_"+album.name),
            title: album.name,
            artist: album.artist.name,
            ELO: ELO_INITAL_RATING,
            coverURL: (album.image[album.image.length-1]['#text'] === "" ? missing_texture : album.image[album.image.length-1]['#text'])
          }
        })
        );
    })
  }

  function removeAlbum(album: Album) {
    const updatedList = albumList.filter((obj) => obj !== album)
    setAlbumList(updatedList);
  }

  const handleUpdateAlbumList = (newAlbumList: Album[] ) => {
    setAlbumList(newAlbumList);
  };

  const resetELOs = () => {
    const resetAlbumList: Album[] = albumList
    resetAlbumList.map((album) => (
        album.ELO = ELO_INITAL_RATING
    ));
    setAlbumList(resetAlbumList);
    console.log("ELOS RESET")
  };

  return (
    <>
      <form className="flex" onSubmit={handleSubmit}>
        <span className="inline-flex items-center">@</span>
        <input
            type="text"
            className="block w-full py-2 px-3 border border-gray-300 rounded-none text-black"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button className="bg-green-500 hover:bg-green-700 py-2 px-4 rounded-r-md" type="submit">Submit</button>
      </form>
      {validUser ? <div className="flex justify-center flex-wrap">
        {['overall', '7day', '1month', '3month', '6month', '12month'].map((tf, index) => (
          <button
            className={selectedTimeFrame === tf ? 'bg-green-500 hover:bg-green-700 py-2 px-4 rounded' : 'bg-blue-500 hover:bg-blue-700 py-2 px-4 rounded'}
            key={index}
            onClick={() => handleTimePeriodClick(tf)}
            style={{ margin: '5px' }}
          >
            {tf}
          </button>
        ))}
        </div> : <p>Enter Username</p>}
        {(albumList.length < 2) ? <></>
        : <AlbumContest albumList={albumList} updateAlbumList={handleUpdateAlbumList}/>
        }
        <h1>RESULTS</h1>
        {(albumList.length < 2) ? <></>
        : <p>Using user {username} and time frame: {selectedTimeFrame}</p>
        }
        {(username !== "" && selectedTimeFrame !=="") ?
        <>
          <button
          className="bg-blue-500 hover:bg-blue-700 py-2 px-4 rounded"
          onClick={() => handleRefreshChart()}>
            Refresh chart
          </button>
        </>
        : <></>
        }
        {DisplayAlbumList({albumList, removeAlbum})}
        <div className="reset"><button className="bg-red-500 hover:bg-red-700 py-2 px-4 rounded" onClick={resetELOs}>Reset Rankings</button></div>
    </>
  )
}
